import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import NavOverlayElements from "./NavOverlayElements.js"
import "./NavOverlay.css";

/// menuData comes from the site that has navOverlay in it, right now it's index.js
const NavOverlay = (props) => {
  const [overlayClasses, setOverlayClasses] = useState("overlay preload");
  const [navOpen, setNavOpenState] = useState(false);
  useEffect(() => {
    setOverlayClasses("overlay")
  }, [])
  return(
    <div className={classNames(overlayClasses, navOpen && "clickableOverlay")}>
      <div className="overlayGrid">
        <NavOverlayElements {...props} navOpen={navOpen} setNavOpenState={setNavOpenState} />
      </div>
    </div>
  )
}

export default NavOverlay
