import React, { useState, memo, useMemo, useEffect, useCallback } from "react";
import {
  get,
  isEmpty,
  map,
  includes,
  concat,
  find,
  split,
  size,
  join,
  dropRight,
  filter,
} from "lodash";
import { Link, navigate } from "gatsby";
import classNames from "classnames";
import { modifyURL, flattenElementsList } from "../../utils/helpers";
import { slide as Menu } from "react-burger-menu";
import LandingPageSchemeButton from "../LandingPageSchemeButton/LandingPageSchemeButton.js";
import closeSVG from "../../images/close.svg";
import arrowSVG from "../../images/arrow.svg";
import arrowSVGWhite from "../../images/arrowWhite.svg";

const activeEnv = "production";
// process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

const getAllParentIds = (array) => {
  if (size(array) < 2) return array;
  return concat([join(array, "-")], getAllParentIds(dropRight(array)));
};

const LinkElement = ({ label, url, toggleNavMenu, triggerAltMenu }) => {
  if (url === "/index") {
    return (
      <button onClick={triggerAltMenu}>
        <span>{label}</span>
      </button>
    );
  }
  return (
    <Link to={url} onClick={toggleNavMenu}>
      {label}
    </Link>
  );
};

const MenuItem = ({
  element = {},
  toggleItemVisible,
  isItemActive,
  isItemOpen,
  isItemVisible,
  toggleNavMenu,
  triggerAltMenu,
}) => {
  const { url, label, indent, type } = element;
  if (type === "link") {
    return (
      <div
        className={classNames(
          "menu-item",
          `indent${indent}`,
          isItemActive && "active",
          isItemVisible && "visible"
        )}
      >
        <LinkElement
          label={label}
          url={url}
          toggleNavMenu={toggleNavMenu}
          triggerAltMenu={triggerAltMenu}
        />
      </div>
    );
  }
  if (type === "dropdown") {
    return (
      <div
        className={classNames(
          "menu-item",
          `indent${indent}`,
          isItemOpen && "opened",
          isItemVisible && "visible",
          isItemActive && "active"
        )}
      >
        <button className={"menu-item-toggle"} onClick={toggleItemVisible}>
          <div className="line-horizontal" />
          <div className="line-vertical" />
        </button>
        <Link to={url} onClick={toggleNavMenu}>
          {label}
        </Link>
      </div>
    );
  }
  return null;
};

const BurgerButtonElement = memo(({ visible, onChange, navOpen }) => {
  if (visible) {
    return (
      <div className="burgerMenu">
        <input
          checked={navOpen}
          onChange={onChange}
          type="checkbox"
          href="#"
          className="burgerButton"
          name="burgerButton"
          id="burgerButton"
        />
        <label className="burgerButtonLabel" htmlFor="burgerButton">
          <span className="lines line1"></span>
          <span className="lines line2"></span>
          <span className="lines line3"></span>
        </label>
      </div>
    );
  }
  return null;
});

const SideMenuElements = ({
  elementList,
  currentPath,
  getToggleItemOpenFunction,
  openedMenuItems,
  toggleNavMenu,
  triggerAltMenu,
}) => {
  if (isEmpty(elementList)) return null;
  return map(elementList, (element = {}) => {
    const { url, id } = element;
    const isItemActive = includes(currentPath, url);
    // TODO: following function should be written recursively
    const getIsItemVisible = () => {
      const splitId = split(id, "-");
      if (size(splitId) < 2) return true;
      if (size(splitId) === 2)
        return (
          includes(openedMenuItems, id) || includes(openedMenuItems, splitId[0])
        );
      if (size(splitId) === 3)
        return (
          includes(openedMenuItems, id) ||
          includes(openedMenuItems, `${splitId[0]}-${splitId[1]}`)
        );
      return (
        includes(openedMenuItems, id) ||
        includes(openedMenuItems, `${splitId[0]}-${splitId[1]}-${splitId[2]}`)
      );
    };
    const isItemOpen = includes(openedMenuItems, id);
    return (
      <MenuItem
        key={id}
        element={element}
        isItemActive={isItemActive}
        isItemVisible={getIsItemVisible()}
        isItemOpen={isItemOpen}
        toggleItemVisible={getToggleItemOpenFunction(id)}
        toggleNavMenu={toggleNavMenu}
        triggerAltMenu={triggerAltMenu}
      />
    );
  });
};

const NavOverlayElements = ({
  data,
  type,
  image,
  menuData,
  currentPath,
  isInfoPage,
  navOpen,
  setNavOpenState,
  hasBackButton,
  indexPageMenuData,
}) => {
  const toggleNavMenu = useCallback(() => {
    setNavOpenState(!navOpen);
  }, [navOpen]);

  const [openedMenuItems, setOpenedMenuItems] = useState([]);
  const [useInfoPageDesign, setUseInfoPageDesign] = useState(false);
  const [timeoutFinished, setTimeOutFinished] = useState(false);
  const menuElements = get(menuData, "menuItems.nodes");
  const altMenuElements = get(indexPageMenuData, "menuItems.nodes");
  const elementList = useMemo(
    () => flattenElementsList(menuElements, isInfoPage),
    [menuElements, isInfoPage]
  );
  const alternativeElementsList = useMemo(
    () => flattenElementsList(altMenuElements, false),
    [menuElements]
  );

  const [menuDataToUse, setMenuDataToUse] = useState(elementList);
  const titleFromUrl = (url) => {
    if (url === null || url === undefined) {
      return null;
    }
    return url
      .replace(
        activeEnv === "development"
          ? "http://mets.tartu.tech"
          : "https://wp.kasvukohatyybid.emu.ee/",
        ""
      )
      .replace("-", " ")
      .slice(0, -1);
  };

  const pageCategory = get(data, "categories.nodes[0].name");

  const navigatedownURL = modifyURL(get(data, "detailPage.navigatedown"));
  const navigateupURL = modifyURL(get(data, "detailPage.navigateup"));
  const navigateleftURL = modifyURL(get(data, "detailPage.navigateleft"));
  const navigaterightURL = modifyURL(get(data, "detailPage.navigateright"));

  const navigatedownTitle = titleFromUrl(get(data, "detailPage.navigatedown"));
  const navigateupTitle = titleFromUrl(get(data, "detailPage.navigateup"));
  const navigateleftTitle = titleFromUrl(get(data, "detailPage.navigateleft"));
  const navigaterightTitle = titleFromUrl(
    get(data, "detailPage.navigateright")
  );

  const triggerAltMenu = () => {
    setNavOpenState(false);
    setTimeOutFinished(false);
    setTimeout(() => {
      setUseInfoPageDesign(false);
      setMenuDataToUse(alternativeElementsList);
      setNavOpenState(true);
      setTimeOutFinished(true);
    }, 400);
  };

  useEffect(() => {
    const activeLink = find(menuDataToUse, ({ url }) =>
      includes(currentPath, url)
    );
    const activeId = get(activeLink, "id");
    if (activeId) {
      const splitId = split(activeId, "-");
      const allIds = getAllParentIds(splitId);
      setOpenedMenuItems(concat(openedMenuItems, allIds));
    }
  }, [currentPath, menuDataToUse]);

  useEffect(() => {
    if (isInfoPage) {
      setUseInfoPageDesign(true);
    } else {
      setUseInfoPageDesign(false);
    }
  }, [isInfoPage]);

  useEffect(() => {
    if (!navOpen && !useInfoPageDesign && isInfoPage && timeoutFinished) {
      setTimeout(() => {
        setMenuDataToUse(elementList);
        setUseInfoPageDesign(true);
      }, 400);
    }
  }, [navOpen, isInfoPage, useInfoPageDesign, timeoutFinished]);

  const getToggleItemOpenFunction = (id) => () => {
    if (includes(openedMenuItems, id)) {
      setOpenedMenuItems(
        filter(openedMenuItems, (itemId) => !includes(itemId, id))
      );
    } else {
      setOpenedMenuItems(concat(openedMenuItems, id));
    }
  };

  // RETURNS TOP BLUR IF PAGE IS DETAIL PAGE AND BOTTOM BLUR IF BOTTOM BUTTON PRESENT
  const BlurElements = () => {
    if (pageCategory === "Detail" && type !== "mobile") {
      if (!navigatedownURL) return <div className="topBlur" />;
      return (
        <>
          <div className="topBlur" />
          <div className="bottomBlur" />
        </>
      );
    }
    return null;
  };

  const RightArrowElement = () => {
    if (!navigaterightURL) return null;
    return (
      <Link className="rightArrow ordinateButton" to={navigaterightURL}>
        <img alt="" src={arrowSVG} className="arrowSVG" />
        <p>{navigaterightTitle}</p>
      </Link>
    );
  };

  const LeftArrowElement = () => {
    if (!navigateleftURL) return null;
    return (
      <Link className="leftArrow ordinateButton" to={navigateleftURL}>
        <img alt="" src={arrowSVG} className="arrowSVG" />
        <p>{navigateleftTitle}</p>
      </Link>
    );
  };

  const UpArrowElement = () => {
    if (!navigateupURL) return null;
    return (
      <Link className="upArrow ordinateButton" to={navigateupURL}>
        <img alt="" src={arrowSVG} className="arrowSVG" />
        <p>{navigateupTitle}</p>
      </Link>
    );
  };

  const DownArrowElement = () => {
    if (!navigatedownURL) return null;
    return (
      <Link className="downArrow ordinateButton" to={navigatedownURL}>
        <img alt="" src={arrowSVG} className="arrowSVG" />
        <p>{navigatedownTitle}</p>
      </Link>
    );
  };

  const BackArrowElement = () => {
    if (
      pageCategory === "Detail" ||
      pageCategory === "Forest" ||
      hasBackButton
    ) {
      return (
        <button
          className="backArrow ordinateButton"
          onClick={() => navigate(-1)}
        >
          <div className="backArrowBackgroundCircle">
            <img alt="" src={arrowSVGWhite} className="arrowSVG" />
          </div>
          <p>tagasi</p>
        </button>
      );
    }
    return null;
  };

  const OrdinateSchemeImage = () => {
    if (type === "mobile" && image) {
      return (
        <img
          className="detailOrdinatePreviewOverlay"
          src={image}
          alt="Asukohad Eesti kaardi peal"
        />
      );
    }
    return null;
  };
  return (
    <>
      <BlurElements />
      <BackArrowElement />
      <Menu
        className={"sideNav " + (useInfoPageDesign ? "light" : "")}
        width={"40vw"}
        customBurgerIcon={false}
        customCrossIcon={false}
        right
        isOpen={navOpen}
        onClose={toggleNavMenu}
      >
        <button onClick={toggleNavMenu} className="sideNavClose">
          <img src={closeSVG} alt="Close side navigation" />
        </button>
        <div
          className={classNames(
            "sideNavLinks",
            useInfoPageDesign && "centeredLinks"
          )}
        >
          <SideMenuElements
            elementList={menuDataToUse}
            getToggleItemOpenFunction={getToggleItemOpenFunction}
            currentPath={currentPath}
            openedMenuItems={openedMenuItems}
            toggleNavMenu={toggleNavMenu}
            triggerAltMenu={triggerAltMenu}
          />
        </div>
        <LandingPageSchemeButton schemeButton="Avalehele" url="/" />
      </Menu>
      <OrdinateSchemeImage />
      <BurgerButtonElement
        visible={type !== "mobile"}
        onChange={toggleNavMenu}
        navOpen={navOpen}
      />
      <RightArrowElement />
      <DownArrowElement />
      <LeftArrowElement />
      <UpArrowElement />
    </>
  );
};

export default NavOverlayElements;
