import { get, isEmpty, flatten, map, concat, compact, replace } from "lodash";

export const modifyURL = (oldUrl, isInfoPage) => {
  if (!oldUrl) {
    return null
  } 
  const replaceString = isInfoPage ? '' : '/mets'
  return replace(oldUrl, /^.*\/\/[^\/]+/, replaceString)
}

const mappingIteration = (
  element = {},
  parentId,
  iterationCount,
  isInfoPage
) => {
  const { parentDatabaseId, childItems, label, url, id } = element;
  if (iterationCount === 0 && parentDatabaseId) return null;
  const childNodes = get(childItems, "nodes", []);
  const fullId = parentId ? `${parentId}-${id}` : id;
  const childElements = isEmpty(childNodes)
    ? []
    : flatten(
        map(childNodes, (node) =>
          mappingIteration(node, fullId, iterationCount + 1)
        )
      );
  const currentElement = {
    type: isEmpty(childNodes) ? "link" : "dropdown",
    label,
    url: modifyURL(url, isInfoPage),
    indent: iterationCount,
    id: fullId,
  };
  return concat([currentElement], childElements);
};

export const flattenElementsList = (elements, isInfoPage) =>
  compact(
    flatten(
      map(elements, (element) => mappingIteration(element, null, 0, isInfoPage))
    )
  );
